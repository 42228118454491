import React, { useEffect, useState } from 'react'
import {
  SWAN_STYLE_KEY_MAP,
} from '@vp/swan'
import { useSwanStyleKeys } from '@vp/ubik-context'
import { TrackingProvider } from './hooks/TrackingContext'
import { CampaignProvider } from '@vp/campaign-callouts'
import TilesSection from './components/TilesSection'
import { isBrowser } from './utils/shared'
import { ContentfulContextProvider, ContentfulLink, DebugSection } from '@vp/mcs-react-tools'

export type AppProps = {
  locale: string,
  tenant: string,
  eagerlyLoad: boolean,
  promoCodeText: string,
  tileRow: any
  usesMap?: boolean
  contentfulMetadata?: any
  debugAvailability?: any
}

export const Fragment = (props: AppProps) => {
  const [hashExists, setHashExists] = useState(false)
  useEffect(() => {
    setHashExists(isBrowser() && window.location.hash !== '')
  }, [])

  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.utility,
  ])

  const search = typeof window !== 'undefined' ? window.location.search : ''

  if (!props.tileRow) { return <></> }

  return (
    <ContentfulContextProvider space={props.contentfulMetadata?.spaceId || ''}>
      <TrackingProvider canonicalUrl='https://www.vistaprint.com/offers'>
        <CampaignProvider tenant={props.tenant} locale={props.locale}>
          {!props.usesMap && (
            <>
              <ContentfulLink id={props.contentfulMetadata?.contentful_id} />
              <DebugSection
                contentfulMetadata={props.contentfulMetadata}
                availability={props.debugAvailability}
                search={search}
              />
            </>
          )}
          <TilesSection
            tileImageLoadingStrategy={props.eagerlyLoad || hashExists ? 'eager' : 'lazy'}
            leadInTitle={props.tileRow.leadInTitle}
            tileData={props.tileRow.tiles}
            hasBanner={!!props.tileRow.bannerData}
            tileTrackingZone={props.tileRow.tileTrackingZone}
            promoCodeText={props.promoCodeText}
          />
        </CampaignProvider>
      </TrackingProvider>
    </ContentfulContextProvider>
  )
}
