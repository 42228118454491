import React from 'react'
import { Box, Row, Column, H3, GridContainer, BoundedContent } from '@vp/swan'
import TilesList from './TilesList'

const TilesSection = props => {
  const { hasBanner, tileData, leadInTitle, tileImageLoadingStrategy, promoCodeText, tileTrackingZone } = props
  return (
    <BoundedContent>
      <GridContainer>
        <Box mb='between-sections'>
          {props.leadInTitle && (
            <Row
              data-testid='spacing'
              marginTop={{ xs: 7, md: !hasBanner ? 0 : 9 }}
              paddingLeft={{ md: 2 }}
              marginLeft={{ xs: 4, md: 7 }}
              marginBottom={{ xs: 4, md: 5 }}
            >
              <Column span={12} padding={0}>
                {leadInTitle.trim().length > 0 && (
                  <H3 margin={0} fontSize='x2large' fontWeight='bold' fontSkin='title-section'>
                    {leadInTitle}
                  </H3>
                )}
              </Column>
            </Row>
          )}
          {tileData && (
            <Row
              marginTop={!leadInTitle ? { xs: 7, md: 9 } : {}}
              paddingLeft={{ md: 1 }}
              marginBottom={{ xs: 5, md: 0 }}
              paddingRight={{ md: 1 }}
              marginLeft={{ xs: 2, md: 6 }}
              marginRight={{ xs: 2, md: 6 }}
              component='ul'
            >
              <TilesList
                loadType={tileImageLoadingStrategy}
                list={tileData}
                promoCodeText={promoCodeText}
                trackingPageZone={tileTrackingZone}
              />
            </Row>
          )}
        </Box>
      </GridContainer>
    </BoundedContent>
  )
}

export default TilesSection
