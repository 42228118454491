export const getUrlIdForTracking = urlData => {
  if (urlData) {
    const linkType = urlData.linkType
    if (linkType) {
      const lowerLinkType = linkType.toLowerCase()
      return lowerLinkType === 'external' ? urlData.trackingId : urlData.actionId
    }
  }
  return null
}
